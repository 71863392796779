/*
 * @Brief:
 * @Description:
 * @Author: yangjianming
 * @Date: 2022-01-05 11:01:36
 */

export const defaultState = {
    value: true,
    citySimpleName: '',
    selectMenuKey: ''
}

export function reducer(state, action) {
    switch (action.type) {
        case 'ONCLICK_STATUS':
            return { ...state, value: !state.value, citySimpleName: action.payload.citySimpleName}
        // case 'CITY_CHANGE':
        //     return { ...state, value: action.payload.citySimpleName }
        case 'MENU_KEY':
            return {...state, value: !state.value, selectMenuKey: action.payload.selectMenuKey}
        case 'RELOAD':
            return {...state, value: !state.value, loading: action.payload.loading}
        case 'COLLAPSED_MENU':
            return {...state, collapsed: action.payload.collapsed}
        default:
            throw new Error()
    }
}
