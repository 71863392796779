/*
 * @Brief:
 * @Description: 格式[{id: '', name: 'xxx'}]
 * @Author: yangjianming
 * @Date: 2021-12-16 16:17:13
 */

import React from 'react'
import { Select } from 'antd'
import './index.less'
const { Option } = Select

const Aselect = ({
    bordered = true,
    showArrow = true,
    optionList,
    callback,
    slMarginRight = 29,
    widthSelecct = 133,
    defaultValueKey = null,
    mode = null,
    value
}) => {
    return (
        <>
            {optionList[0] && (
                <Select
                    listHeight={150}
                    bordered={bordered}
                    mode={mode}
                    showArrow={showArrow}
                    value={value}
                    defaultValue={defaultValueKey ? defaultValueKey : optionList[0].id}
                    style={{ width: widthSelecct, marginRight: slMarginRight }}
                    onChange={callback}>
                    {optionList &&
                        optionList.map((val, i) => (
                            <Option key={i} value={val.id}>
                                {val.name}
                            </Option>
                        ))}
                </Select>
            )}
        </>
    )
}

export default Aselect
