/*
 * @Brief: 
 * @Description: 人口文件
 * @Author: yangjianming
 * @Date: 2021-12-08 14:13:03
 */
import React from "react"
import ReactDOM from "react-dom"
import { ConfigProvider } from "antd"
import zhCN from "antd/lib/locale/zh_CN"

import App from "./App"

ReactDOM.render(
  <ConfigProvider locale={zhCN}>
    <App />
  </ConfigProvider>,
  document.getElementById("root")
)
