/*
 * @Brief:
 * @Description: 处理url
 * @Author: yangjianming
 * @Date: 2022-04-26 17:47:34
 */

import storageUtils from './storageUtils'
import md5 from 'md5'

// const p_token = cookie.load('supervise_token') || ''
const secretKey = 'ppvzj4t2dwuxdpcgrw04sdjtpl85xdf4'

export const getParams = props => {
    let result = {}
    const queryString = props.location.search
    let reg = /[?&][^?&]+=[^?&]+/g
    let newSearch = queryString.match(reg)
    if (newSearch) {
        newSearch.forEach(item => {
            let temp = item.substring(1).split('=')
            let key = temp[0]
            let value = temp[1]
            result[key] = value
        })
    }
    return result
}

//签名可以提取一个fuction
export const getImgUrl = (url, needParam = true, isWidth = false, elHeight = 126) => {
    const p_token = storageUtils.getToken() || ''
    const p_cityId = storageUtils.getCityId()

    const frontText = `${window.location.href.split('#')[0]}erpapi/file/image?key=${encodeURIComponent(url)}`
    // let param = `&param=${encodeURIComponent('imageMogr2/thumbnail/x')}${elHeight}`

    const imageParam = {
        key: url,
        token: p_token,
        cityId: p_cityId,
        deviceType: 2
    }
    if (!needParam) {
        imageParam.param = ''
    } else {
        if (isWidth) {
            imageParam.param = `imageMogr2/thumbnail/${isWidth}x`
        } else {
            imageParam.param = `imageMogr2/thumbnail/x${elHeight}`
        }
    }
    let sign = getSignParam(imageParam)
    return `${frontText}&param=${encodeURIComponent(
        imageParam.param
    )}&token=${p_token}&cityId=${p_cityId}&deviceType=2&sign=${sign}`
}

export const getSignParam = data => {
    const sign = md5((jsonToUrl(data, true) + '&' + secretKey).replace(/^\&*/, ''))
    return sign
}

export const jsonToUrl = (json, sort = false, encode = false) => {
    var result = ''
    var data = json

    if (sort) {
        var sortedData = {}
        var sortedKeys = Object.keys(json).sort()
        sortedKeys.forEach(function (key) {
            sortedData[key] = json[key]
        })
        data = sortedData
    }
    for (var item in data) {
        if (data.hasOwnProperty(item)) {
            if (encode) {
                result += '&' + item + '=' + encodeURIComponent(data[item])
            } else {
                result += '&' + item + '=' + data[item]
            }
        }
    }
    return result
}


// 导出
export const exportSignParam = (url, query) => {
    query.sign = getSignParam(query)
    return url + jsonToUrl(query).replace('&', '?')
}