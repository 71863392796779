import React, { useState, useEffect, useCallback, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import { Form, Input, Button, message, Modal } from 'antd'
import ReactCanvasNest from 'react-canvas-nest'
// import cookie from 'react-cookies'
// import Lphone from '@/assets/images/l_phone.png'
// import Lmessage from '@/assets/images/l_message.png'
import { reLogin, getUserInfo, sendSMSCaptcha, createToken } from '@/api/login'
import storageUtils from '@/utils/storageUtils'
import './login.less'
// import '@/mock/login/index'

const FormItem = Form.Item

const Login = props => {
    const [showGetPwd, setShowGetPwd] = useState(false)
    const [showTime, setShowTime] = useState(false)
    const [cxString, setCxString] = useState('')
    const [count, setCount] = useState(0)
    const [phone, setPhone] = useState('')
    const [loading, setLoading] = useState(false)
    const [title, setTitle] = useState('有序智停')
    const [form] = Form.useForm()
    const { history } = props
    const intervalRef = useRef(null)

    useEffect(() => {
        //域名是否包含 jianguan.miaoting.cc
        const IsMiaoting = window.location.href.split('#')[0].includes('jianguan.miaoting.cc')
        if(IsMiaoting){
            setTitle('秒停')
            document.getElementsByTagName("title")[0].innerText = '秒停';
        }

        storageUtils.removeUser()
        storageUtils.removeCityId()
        storageUtils.removeCityName()
        storageUtils.removeCityLongName()
        createTokenFn()
        // eslint-disable-next-line
    }, [])

    // 组件卸载时清除计时器
    useEffect(() => {
        return () => {
            clearInterval(intervalRef.current)
        }
    }, [])

    //倒计60s
    useEffect(() => {
        if (count <= 59 && count > 0) {
            if (count === 1) {
                setCxString('重新')
            }
            intervalRef.current = setInterval(() => {
                setCount(preCount => preCount - 1)
            }, 1000)
        } else if (count === 0) {
            setShowTime(false)
            clearInterval(intervalRef.current)
        }
        return () => {
            clearInterval(intervalRef.current)
        }
    }, [count])
    const createTokenFn = async () => {
        try {
            const { data } = await createToken({ appVersion: '1.0' })
            // data && cookie.save('supervise_token', data.token)
            data && storageUtils.saveToken(data.token)
        } catch (error) {}
    }
    const onGetCaptcha = useCallback(() => {
        setCount(59)
    }, [])
    const isChrome = () => {
        const userAgent = navigator.userAgent.indexOf('Chrome') > -1
        if (!userAgent) {
            Modal.confirm({
                title: '为保证更好的体验，建议您下载Chrome浏览器',
                okText: '前往下载',
                cancelText: '不用了',
                onOk: () => {
                    window.open(
                        'https://www.google.cn/chrome/thank-you.html?statcb=0&installdataindex=empty&defaultbrowser=0#'
                    )
                },
                onCancel: () => {}
            })
        }
    }
    const handleSubmit = async values => {
        isChrome()
        setLoading(true)
        try {
            const res = await reLogin(values)
            if (res.status === 0) {
                storageUtils.saveCityId(res.data.cityId)
                const item = await getUserInfo()
                if (item.status === 0) {
                    storageUtils.saveUser(item.data)
                    history.push('/home')
                    message.success('登陆成功！')
                } else {
                    setLoading(false)
                }
            } else {
                createTokenFn()
                setLoading(false)
            }
        } catch (error) {
            setLoading(false)
        }
    }

    const onChangePhoneNum = ({ target: { value } }) => {
        const myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/
        if (myreg.test(value)) {
            setShowGetPwd(true)
            setPhone(value)
        } else {
            setShowGetPwd(false)
        }
    }
    const getPassword = async () => {
        onGetCaptcha()
        setShowTime(true)
        const res = await sendSMSCaptcha({ phone })
        if (res.status !== 0) createTokenFn()
    }
    return (
        <div className="l_bg flex">
            <ReactCanvasNest
                className="canvasNest"
                config={{
                    pointColor: '255, 255, 255',
                    lineColor: '255,255,255',
                    pointOpacity: 0.5, // 点与点之间的线的颜色
                    pointR: 4, //粒子的半径
                    count: 120, //粒子数量
                    dist: 4000 //两点之间的最大距离
                }}
                style={{ zIndex: 1 }}></ReactCanvasNest>
            <div className="l_body flex-start">
                <div className="l_title flex-column-s-b">
                    <div className="l_l_title flex-column-s">
                        <p>Hi！您好！</p>
                        <p>{`欢迎进入${title}共享出行监管平台`}</p>
                    </div>
                    <img src="https://fecdn.qeebike.com/supervise/bg_pic.png" alt="" />
                </div>
                <div className="login flex">
                    <div className="login_content">
                        <div className="login_title">
                            <p>登录系统</p>
                        </div>
                        <div>
                            <Form form={form} onFinish={handleSubmit} className="login_form">
                                <FormItem
                                    label=""
                                    name="phone"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: '手机号不能为空!'
                                        },
                                        {
                                            pattern: /^[1][3,4,5,6,7,8,9][0-9]{9}$/,
                                            message: '请输入正确的手机号！'
                                        }
                                    ]}>
                                    <div>
                                        <p>手机号码</p>
                                        <div className="l_message_box">
                                            <Input
                                                placeholder="手机号"
                                                onChange={onChangePhoneNum}
                                                size="large"
                                                maxLength={11}
                                            />
                                        </div>
                                    </div>
                                </FormItem>
                                <FormItem
                                    label=""
                                    name="code"
                                    rules={[
                                        {
                                            required: true,
                                            whitespace: true,
                                            message: '验证码不能为空!'
                                        },
                                        {
                                            min: 6,
                                            max: 6,
                                            message: '验证码为6位!'
                                        },
                                        {
                                            pattern: /^[0-9]+$/,
                                            message: '验证码为数字组成!'
                                        }
                                    ]}>
                                    <div className="">
                                        <p>验证码</p>
                                        <div className="l_message_box">
                                            <Input placeholder="验证码" size="large" maxLength={6} className="l_message_input" />
                                            {showTime ? (
                                                <span className="login_getPwd login_btn_box">{count}秒后重新发送</span>
                                            ) : (
                                                <span
                                                    onClick={getPassword}
                                                    className={
                                                        showGetPwd
                                                            ? 'login_can login_btn_box'
                                                            : 'login_getPwd login_btn_box'
                                                    }>
                                                    {cxString}获取验证码
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </FormItem>
                                <FormItem>
                                    <Button
                                        type="primary"
                                        loading={loading}
                                        htmlType="submit"
                                        className="login_form_button">
                                        登 录
                                    </Button>
                                </FormItem>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(Login)
