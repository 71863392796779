/*
 * @Brief:
 * @Description:
 * @Author: yangjianming
 * @Date: 2021-12-10 11:12:07
 */
import axios from 'axios'
import qs from 'qs'
import { message, notification } from 'antd'
import storageUtils from '@/utils/storageUtils'
import { getSignParam } from '@/utils/urlUtil'

export const request = (url, data = {}, method = 'GET', tipsText = null) => {

    data['token'] = storageUtils.getToken() || ''
    data['cityId'] = storageUtils.getCityId()
    data['deviceType'] = 2
    let newData = {},
        options = {}
    // 循环对象，判断对象中某些属性是否存在，且排除属性值为空格的属性
    for (let key in data) {
        if ((data[key] || data[key] === 0) && data[key].toString().replace(/(^\s*)|(\s*$)/g, '') !== '') {
            newData[key] = data[key]
        }
    }
    //签名需要在空参数过滤后
    newData['sign'] = getSignParam(newData)
    if (['POST', 'PUT'].indexOf(method) > -1) {
        options = {
            method: method,
            headers: { 'content-type': 'application/x-www-form-urlencoded' },
            data: qs.stringify(newData),
            url
        }
    } else {
        options = {
            method: method,
            params: newData,
            url
        }
    }

    return axios(options)
        .then(res => {
            const resStatus = res.data.status
            if (resStatus === 0) {
                tipsText && notification.success({ message: tipsText })
                return res.data
            // } else if ([2, 7, 1001, 1002, 1003].includes(resStatus)) {
            } else if ([2, 7, 1001, 1002].includes(resStatus)) {
                console.log('没登录')
                message.destroy()
                message.error('请求出错了' + res.data.msg)
                storageUtils.removeToken()
                storageUtils.removeUser()
                storageUtils.removeCityId()
                storageUtils.removeCityName()
                storageUtils.removeCityLongName()
                window.location.href = '//' + window.location.hostname + '/#/login'
                return res.data
            } else {
                // message.error('请求出错了' + res.data.msg)
                return Promise.reject(res.data.msg)
            }
        })
        .catch(err => {
            console.log(`err`, err)
            message.error('请求出错了：' + err)
        })
}
