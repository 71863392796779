/*
 * @Brief: 
 * @Description: 
 * @Author: yangjianming
 * @Date: 2022-12-29 14:55:36
 */
import React, { useContext, useState, useEffect, useMemo } from 'react'
import { withRouter } from 'react-router-dom'
import { getMenuList } from '@/api/home'
import { Context } from '@/App'

import './index.less'

let draggableList = []
let menuListArr = []
const TopMenu = props => {
    const AppContext = useContext(Context)
    const [menuNodeList, setMenuNodeList] = useState([])
    const {
        location: { pathname }
    } = props

    const getMenuDate = async () => {
        try {
            const { data } = await getMenuList()
            setMenuNodeList(data)
        } catch (error) {}
    }

    const menuArr = useMemo(() => {
        let res = [];
        let path = pathname || AppContext.state.selectMenuKey
        let keys = path.split('/').filter(i => i)
        let filterMenuList = menuNodeList && menuNodeList.length > 0 && menuNodeList.find(item => keys.includes(item.englishName))
        if (filterMenuList.child && filterMenuList.child.length > 0) {
            let childMenuList = filterMenuList.child.find(item => keys.includes(item.englishName))


            draggableList.push({
                id: childMenuList.id,
                name: childMenuList.name,
                englishName: childMenuList.englishName,
                path: `/${filterMenuList.englishName}/${childMenuList.englishName}`
            })

            let data = []
            draggableList.forEach(item => {
                if (item.id === childMenuList.id) {
                    data.push(item)
                }
            })
        }
        return res
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AppContext.state.selectMenuKey, menuNodeList])
    
    useEffect(() => {
        getMenuDate()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="top_c">
            {menuNodeList.map((item, i) => {
                return (
                    // <Link to={item.path} key={i} className="c_m">
                    //     {item.name}
                    //     <CloseOutlined className="anticon-close" />
                    // </Link>
                    <div key={i}>{item.name}</div>
                )
            })}
        </div>
    )
}

export default withRouter(TopMenu)
