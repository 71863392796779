import loadableHandler from './components/loadableHandler/index.jsx'

const WORK = '/work'
const CITY = '/city'
const AUTH = '/auth'

const routes = [
  {
    path: `${WORK}/work`,
    isExact: true,
    component: loadableHandler(() => import('./pages/work/work')),
    children: []
  },
  {
    path: `${WORK}/screen`,
    component: loadableHandler(() => import('./pages/work/screen/screen')),
    children: []
  },
  {
    path: `${WORK}/copywriting`,
    component: loadableHandler(() => import('./pages/work/copywriting/copywriting')),
    children: []
  },
  {
    path: `${WORK}/home`,
    isExact: true,
    component: loadableHandler(() => import('./pages/work/home/home')),
    children: []
  },
  {
    path: `${CITY}/bikeManage`,
    isExact: true,
    component: loadableHandler(() => import('./pages/city/bike/bike')),
    children: []
  },
  {
    path: `${CITY}/regionManagement/regionManage`,
    isExact: true,
    component: loadableHandler(() => import('./pages/city/region/region')),
    children: []
  },
  {
    path: `${CITY}/regionManagement/regionManage/detail/:id`,
    component: loadableHandler(() => import('./pages/city/region/regionDetail')),
    children: []
  },
  {
    path: `${CITY}/dynamicSupervision/launchManage`,
    component: loadableHandler(() => import('./pages/city/launch/launch')),
    children: []
  },{
    path: `${CITY}/regionManagement/device`,
    isExact: true,
    component: loadableHandler(() => import('./pages/city/device/device')),
    children: []
  },
  {
    path: `${CITY}/regionManagement/monitor`,
    isExact: true,
    component: loadableHandler(() => import('./pages/city/monitor/monitor')),
    children: []
  },
  {
    path: `${CITY}/regionManagement/monitor/detail`,
    component: loadableHandler(() => import('./pages/city/monitor/monitorDetail')),
    children: []
  },
  {
    path: `${CITY}/regionManagement/supervision`,
    isExact: true,
    component: loadableHandler(() => import('./pages/city/supervision/supervision')),
    children: []
  },
  {
    path: `${CITY}/system`,
    isExact: true,
    component: loadableHandler(() => import('./pages/city/system/system')),
    children: []
  },
  {
    path: `${CITY}/dynamicSupervision/workorder`,
    isExact: true,
    component: loadableHandler(() => import('./pages/city/workorder/workorder')),
    children: []
  },
  {
    path: `${CITY}/dynamicSupervision/workorder/detail`,
    component: loadableHandler(() => import('./pages/city/workorder/workorderDetail')),
    children: []
  },
  {
    path: `${CITY}/dynamicSupervision/schedule`,
    isExact: true,
    component: loadableHandler(() => import('./pages/city/schedule/schedule')),
    children: []
  },
  {
    path: `${CITY}/dynamicSupervision/schedule/detail`,
    component: loadableHandler(() => import('./pages/city/schedule/scheduleDetail')),
    children: []
  },
  {
    path: `${CITY}/complaints`,
    isExact: true,
    component: loadableHandler(() => import('./pages/city/complaints/complaints')),
    children: []
  },
  {
    path: `${CITY}/complaints/detail`,
    component: loadableHandler(() => import('./pages/city/complaints/complaintsDetail')),
    children: []
  },
  {
    path: `${CITY}/enterprise`,
    isExact: true,
    component: loadableHandler(() => import('./pages/city/enterprise/enterprise')),
    children: []
  },
  {
    path: `${CITY}/enterprise/detail`,
    isExact: true,
    component: loadableHandler(() => import('./pages/city/enterprise/enterpriseDetail')),
    children: []
  },
  {
    path: `${AUTH}/enterprisePerson`,
    isExact: true,
    component: loadableHandler(() => import('./pages/auth/enterprisePerson/enterPrisePerson')),
    children: []
  },
  {
    path: `${AUTH}/violate`,
    isExact: true,
    component: loadableHandler(() => import('./pages/auth/violate/violate')),
    children: []
  },
  {
    path: `${AUTH}/personnelManage`,
    component: loadableHandler(() => import('./pages/auth/personnel/personnel')),
    children: []
  },
  {
    path: `${AUTH}/interfaceManage`,
    component: loadableHandler(() => import('./pages/auth/interface/interface')),
    children: []
  },
  {
    path: `${AUTH}/jurisdictionManage`,
    component: loadableHandler(() => import('./pages/auth/jurisdiction/jurisdiction')),
    children: []
  },
  {
    path: `${AUTH}/cityList`,
    component: loadableHandler(() => import('./pages/auth/city/city')),
    children: []
  },
  {
    path: `${AUTH}/roleManage`,
    component: loadableHandler(() => import('./pages/auth/role/role')),
    children: []
  },
]

export default routes
