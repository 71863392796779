/*
 * @Brief:
 * @Description: 侧边栏menu
 * @Author: yangjianming
 * @Date: 2022-04-12 17:20:22
 */

import { useEffect, useState, useContext } from 'react'
import { Menu } from 'antd'
import { Link, withRouter } from 'react-router-dom'
import { getMenuList } from '@/api/home'
import { Context } from '@/App'
import logo from '@/assets/images/logo.png'
import meunLog from '@/assets/images/meunLog.png'

import './index.less'

const { SubMenu } = Menu
const LeftNav = props => {
    const AppContext = useContext(Context)
    const {
        location: { pathname }
    } = props
    const [openKeys, setOpenKeys] = useState([''])
    const [current, setCurrent] = useState('')
    const [menuNodeList, setMenuNodeList] = useState([])
    const [menuLogType, setMenuLogType] = useState(logo)

    const handleOpenKeysChange = openKeysParam => {
        const latestOpenKey = openKeysParam.find(key => !(openKeys.indexOf(key) > -1))
        const latestCloseKey = openKeys.find(key => !(openKeysParam.indexOf(key) > -1))

        let nextOpenKeys = []
        if (latestOpenKey) {
            nextOpenKeys = recomposeKey(latestOpenKey).concat(latestOpenKey)
        }
        if (latestCloseKey) {
            nextOpenKeys = recomposeKey(latestCloseKey)
        }
        setOpenKeys(nextOpenKeys)
    }
    const recomposeKey = keyStr => {
        let keys = keyStr.split('/')
        let resultArr = []
        let prevPath = ''
        for (let i = 1; i < keys.length - 1; i++) {
            prevPath = `${prevPath}/${keys[i]}`
            resultArr.push(prevPath)
        }
        return resultArr
    }
    const handleSelectItem = data => {
        AppContext.dispatch({
            type: 'MENU_KEY',
            payload: { selectMenuKey: data.key }
        })
        setCurrent(data.key)
    }
    //函数递归
    const getMenuListFn = (menuList, parentPath) => {
        if (Array.isArray(menuList) && menuList.length > 0) {
            return menuList.map(item => {
                let newPath = `${parentPath}/${item.englishName}`
                if (item && ['base', 'home'].includes(item.englishName)) return null
                if (item.child && item.child.length > 0) {
                    const titleIcon = !item.parentId ? (
                        <span className="title-icon">
                            <i className={`iconfont icon-${item.englishName}`} />
                        </span>
                    ) : null
                    return (
                        <SubMenu
                            key={newPath}
                            title={
                                <span>
                                    {titleIcon}
                                    <span>{item.name}</span>
                                </span>
                            }>
                            {getMenuListFn(item.child, newPath)}
                        </SubMenu>
                    )
                    // TODO: 当为工作台, 后期重构为一级目录
                }
                //  else if (item && item.englishName === 'work') {
                //     console.log('item', item)
                //     console.log('newPath', newPath)
                //     return (
                //         <Menu.Item key={newPath}>
                //            <Link to={`${newPath}`}>
                //                 <span className="title-icon">
                //                     <i className={`iconfont icon-${item.englishName}`} />
                //                 </span>
                //                 {item.name}
                //             </Link>
                //          </Menu.Item>
                //     )
                // }
                else {
                    return (
                        <Menu.Item key={newPath}>
                            <Link to={`${newPath}`}>{item.name}</Link>
                        </Menu.Item>
                    )
                }
            })
        }
    }

    const getMenuDate = async () => {
        try {
            const { data } = await getMenuList()
            setMenuNodeList(getMenuListFn(data, ''))
        } catch (error) {}
    }
    const initNav = () => {
        let path = pathname
        if(path.includes('detail')) {
            path = pathname.split('/detail')[0]
        }
        let openKeys = recomposeKey(path)
        setCurrent(path)
        setOpenKeys(openKeys)
    }

    useEffect(() => {
        //域名是否包含 jianguan.miaoting.cc
        const IsMiaoting = window.location.href.split('#')[0].includes('jianguan.miaoting.cc')
        if (IsMiaoting) setMenuLogType(meunLog)
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        initNav()
        getMenuDate()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AppContext.state.value])

    useEffect(() => {
        initNav()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pathname])
    return (
        <div className="left-nav">
            {AppContext.state.collapsed ? (
                <Link className="left-nav-link" to="/screen"></Link>
            ) : (
                <Link className="left-nav-link" to="/screen">
                    <img src={menuLogType} alt="" />
                    <h1>共享出行监管平台</h1>
                </Link>
            )}

            <Menu
                mode="inline"
                theme="dark"
                // style={{ backgroundColor: '#005CAF', width: '100%', height: '100%', overflow: 'auto' }}
                style={{ width: '100%', height: '100%', overflow: 'auto' }}
                selectedKeys={[current]}
                onSelect={handleSelectItem}
                openKeys={openKeys}
                onOpenChange={handleOpenKeysChange}>
                {menuNodeList}
            </Menu>
        </div>
    )
}

/* 
使用高阶组件withRouter(）来包装非路由组件
新组件向leftNav传递3个特别的属性:history/location/match
 */
export default withRouter(LeftNav)
