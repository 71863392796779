/*
 * @Brief:
 * @Description: 操作local数据的工具类函数模块
 * @Author: yangjianming
 * @Date: 2021-12-08 14:40:24
 */

const USER_KEY = 'user_key'
const CITY_ID = 'city_id'
const CITY_NAME = 'city_name'
const CITY_LONG_NAME = 'city_long_name'
const IS_SCREEN = 'is_screen'
const SUPERVISE_TOKEN = 'supervise_token'
//eslint-disable-next-line
export default {
    saveToken(token) {
        localStorage.setItem(SUPERVISE_TOKEN, token)
    },
    getToken() {
        return localStorage.getItem(SUPERVISE_TOKEN) || ''
    },
    removeToken() {
        localStorage.removeItem(SUPERVISE_TOKEN)
    },
    saveUser(user) {
        localStorage.setItem(USER_KEY, JSON.stringify(user))
    },
    getUser() {
        return JSON.parse(localStorage.getItem(USER_KEY) || '{}')
    },
    removeUser() {
        localStorage.removeItem(USER_KEY)
    },
    saveCityId(cityId) {
        localStorage.setItem(CITY_ID, cityId)
    },
    getCityId() {
        return localStorage.getItem(CITY_ID) || ''
    },
    removeCityId() {
        localStorage.removeItem(CITY_ID)
    },
    saveCityName(cityName) {
        localStorage.setItem(CITY_NAME, cityName)
    },
    getCityName() {
        return localStorage.getItem(CITY_NAME) || ''
    },
    removeCityName() {
        localStorage.removeItem(CITY_NAME)
    },
    saveCityLongName(cityLongName) {
        localStorage.setItem(CITY_LONG_NAME, cityLongName)
    },
    getCityLongName() {
        return localStorage.getItem(CITY_LONG_NAME) || ''
    },
    removeCityLongName() {
        localStorage.removeItem(CITY_LONG_NAME)
    },
    saveScreenFlag(flag) {
        localStorage.setItem(IS_SCREEN, flag)
    },
    getScreenFlag() {
        return localStorage.getItem(IS_SCREEN) || ''
    },
    removeScreenFlag() {
        localStorage.removeItem(IS_SCREEN)
    }
}
