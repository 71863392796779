import React, { useEffect, useState, useContext, useMemo } from 'react'
import { Modal, message, Space} from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { withRouter } from 'react-router-dom'
import { getUserCityList, signOut } from '@/api/home'
import Aselect from '../select/Aselect'
import storageUtils from '@/utils/storageUtils'
import { Context } from '@/App'
// import '@/mock/home/index'

import './index.less'
const headerImage = 'https://cdn.youxu.cc/repo/qiji-erp/default-avatar_709.png'
const Header = props => {
    const AppContext = useContext(Context)
    const [userDetail, setUserDetail] = useState(null)
    const [cityList, setCityList] = useState([])
    const [defaultValueKey, setDefaultValueKey] = useState(null)
    const onClickOut = () => {
        Modal.confirm({
            content: `确认退出？`,
            icon: <ExclamationCircleOutlined />,
            okText: '确认',
            cancelText: '取消',
            onOk: async () => {
                try {
                    const res = await signOut()
                    if (res.status === 0) {
                        message.success('退出成功！')
                        props.history.push('/login')
                        storageUtils.removeToken()
                        storageUtils.removeUser()
                        storageUtils.removeCityId()
                        storageUtils.removeCityName()
                        storageUtils.removeCityLongName()
                    }
                } catch (error) {}
            }
        })
    }
    const getCityName = (cityList, value) => {
        const changeCity = cityList.find(item => item.id === value)
        const name = changeCity.simpleName || changeCity.name
        storageUtils.saveCityName(name)
        storageUtils.saveCityLongName(changeCity.name)
        return name
    }
    const handleCityChange = value => {
        storageUtils.saveCityId(value)
        const name = getCityName(cityList, value)
        AppContext.dispatch({
            type: 'ONCLICK_STATUS',
            payload: { citySimpleName: name }
        })
    }
    const getCityList = async () => {
        try {
            const { data } = await getUserCityList()
            const storagecityId = storageUtils.getCityId()
            getCityName(data, storagecityId)
            setCityList(data || [])
        } catch (error) {}
    }
    useEffect(() => {
        setDefaultValueKey(storageUtils.getCityId())
        getCityList()
        setUserDetail(storageUtils.getUser())
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    return (
        <div className="h_container">
            <div className="h_body flex-s-b">
                <div className="header_city">
                    <i className="iconfont icon-city-solid" style={{ marginRight: 3, color: '#7c7c7c' }}></i>
                    {useMemo(
                        () => (
                            <Space>
                                {/* <span style={{ fontSize: '0.175rem' }}>城市</span> */}
                                <Aselect
                                    defaultValueKey={defaultValueKey}
                                    optionList={cityList}
                                    callback={handleCityChange}
                                    bordered={false}
                                    showArrow={false}
                                />
                            </Space>
                        ),
                        // eslint-disable-next-line react-hooks/exhaustive-deps
                        [cityList]
                    )}
                </div>
                <div className="header_user">
                    <div className="flex-start">
                        <div className="h_user_image">
                            <img alt="" src={userDetail && userDetail.portrait ? userDetail.portrait : headerImage} />
                        </div>
                        <div className="h_info">
                            <div>{userDetail && userDetail.departmentName}</div>
                            <div>
                                {userDetail && userDetail.roleName}-{userDetail && userDetail.name}
                            </div>
                        </div>
                        <div className="h_btn" onClick={onClickOut}>
                            <i className="iconfont icon-logout" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default withRouter(Header)
