/*
 * @Brief: 
 * @Description: 
 * @Author: yangjianming
 * @Date: 2022-12-29 14:55:36
 */
import React, { useEffect, useState, useContext } from 'react'
import { Link, withRouter } from 'react-router-dom'
import { Breadcrumb } from 'antd'
import { getMenuList } from '@/api/home'
import { Context } from '@/App'

import './index.less'

const BreadcrumbCom = props => {
    const AppContext = useContext(Context)
    const [menuNodeList, setMenuNodeList] = useState([])
    const {
        location: { pathname }
    } = props

    const getMenuDate = async () => {
        try {
            const { data } = await getMenuList()
            let path = pathname ? pathname : AppContext.state.selectMenuKey
            getMenuArr(data, path)
        } catch (error) {}
    }

    const getMenuArr = (menuList, path) => {
        let arr = []
        let keys = path.split('/').filter(i => i)
        let filterMenuList = menuList.find(item => keys.includes(item.englishName))
        arr.push({
            id: filterMenuList.id,
            name: filterMenuList.name,
            englishName: filterMenuList.englishName,
            path: `/${filterMenuList.englishName}`
        })
        if (filterMenuList.child && filterMenuList.child.length > 0) {
            let childMenuList = filterMenuList.child.find(item => keys.includes(item.englishName))
            arr.push({
                id: childMenuList.id,
                name: childMenuList.name,
                englishName: childMenuList.englishName,
                path: `/${filterMenuList.englishName}/${childMenuList.englishName}`
            })
        }
        setMenuNodeList(arr)
    }


    useEffect(() => {
        getMenuDate()
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [AppContext.state.selectMenuKey])

    return (
        <>
            {pathname.indexOf('home') === -1 && (
                <div className="breadcrumb_container">
                    <Breadcrumb className="top-breadcrumb">
                        {menuNodeList.map((item, i) => (
                            <Breadcrumb.Item key={i}>
                                <i className={`iconfont icon-${item.englishName}`} />
                                <Link to={item.path}>{item.name}</Link>
                            </Breadcrumb.Item>
                        ))}
                    </Breadcrumb>
                </div>
            )}
        </>
    )
}

export default withRouter(BreadcrumbCom)
