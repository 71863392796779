/*
 * @Brief:
 * @Description:
 * @Author: yangjianming
 * @Date: 2021-12-10 14:02:32
 */
import { request } from './axios'

//创建token
export const createToken = data => request('/erpapi/token/create', data)

//发送短信
export const sendSMSCaptcha = data => request('/erpapi/common/sendCode', data)

//登录
export const reLogin = data => request('/erpapi/user/login', data, 'POST')

//用户信息
export const getUserInfo = data => request('/erpapi/user/detail', data)
