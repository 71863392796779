/*
 * @Brief:
 * @Description: 应用根组件
 * @Author: yangjianming
 * @Date: 2021-12-08 14:13:03
 */
import React, { useReducer, createContext } from 'react'
import { HashRouter, Switch, Route } from 'react-router-dom'
import { reducer, defaultState } from './reducer/reducer'
import './App.less'

import Login from './pages/login/login'
import Admin from './pages/admin/admin'

export const Context = createContext(null)

const App = () => {
    const [state, dispatch] = useReducer(reducer, defaultState)
    return (
        <Context.Provider value={{ state, dispatch: dispatch }}>
            <HashRouter>
                <Switch>
                    <Route path="/login" component={Login} />
                    <Route path="/" component={Admin} />
                </Switch>
            </HashRouter>
        </Context.Provider>
    )
}
export default App
