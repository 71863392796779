import React, { Component } from 'react'

export default class Loading extends Component {
    constructor() {
        super()
        this.state = {
        }
    }
    componentDidMount() {
    }
    render() {
        return (
            <div className="loadable-loading">
                <div className="spinner">
                    <div className="rect1" />
                    <div className="rect2" />
                    <div className="rect3" />
                    <div className="rect4" />
                    <div className="rect5" />
                </div>
            </div>
        )
    }
}
