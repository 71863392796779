/*
 * @Brief:
 * @Description:
 * @Author: yangjianming
 * @Date: 2022-01-14 11:35:49
 */
import React, { useEffect, useContext, useState, useRef } from 'react'
import { Redirect, Switch, Route, useLocation, withRouter } from 'react-router-dom'
// import { browserHistory } from 'react-router'
import { signOut } from '@/api/home'
import { Layout, message } from 'antd'
import { Context } from '@/App'
import Header from '@/components/header'
import LeftNav from '@/components/leftNav'
import Breadcrumb from '@/components/breadcrumb'
import TopMenu from '@/components/topMenu'
import storageUtils from '@/utils/storageUtils'
import routes from '@/routes'
import './admin.less'

const { Content, Sider } = Layout
let maxTime = 30 * 60 // 秒

const Admin = props => {
    const [collapsed, setCollapsed] = useState(false)
    const time = useRef(maxTime)
    const intervalId = useRef(null)
    useEffect(() => {
        var body = document.getElementById('root')
        //监听鼠标移动
        body.onmousemove = function (oEvent) {
            // intervalId.current && clearInterval(intervalId.current)
            time.current = maxTime
        }
        if (!intervalId.current) {
            intervalId.current = setInterval(function () {
                time.current--
                if (time.current <= 0) {
                    logout()
                    clearInterval(intervalId.current)
                }
            }, 1000)
        }
        return () => {
            clearInterval(intervalId.current)
        }
    }, [])
    const logout = async () => {
        const res = await signOut()
        if (res.status === 0) {
            message.success('退出成功！')
            props.history.push('/login')
            storageUtils.removeToken()
            storageUtils.removeUser()
            storageUtils.removeCityId()
            storageUtils.removeCityName()
            storageUtils.removeCityLongName()
        }
    }

    const AppContext = useContext(Context)
    const user = storageUtils.getUser()
    const pathname = useLocation().pathname

    if (!user || !user.uid) {
        return <Redirect to="/login"></Redirect>
    }
    const onCollapse = (value) => {
        setCollapsed(value)
        AppContext.dispatch({
            type: 'COLLAPSED_MENU',
            payload: { collapsed: value }
        })
    }
    return (
        <Layout style={{ height: '100%' }}>
            <Sider width={220} collapsedWidth={30} collapsible collapsed={collapsed} onCollapse={onCollapse}>
                <LeftNav />
            </Sider>
            <Content style={{ overflowY: 'overlay' }}>
                <Header />
                {/* <Breadcrumb /> */}
                <Switch>
                    {routes.map((route, index) => (
                        <Route exact={route.isExact} component={route.component} path={route.path} key={index} />
                    ))}
                    <Redirect to="/work/home" />
                </Switch>
            </Content>
        </Layout>
    )
}

export default withRouter(Admin)
