/*
 * @Brief:
 * @Description:
 * @Author: yangjianming
 * @Date: 2021-12-10 11:16:44
 */

import { request } from './axios'

export const getUserCityList = data => request('/erpapi/user/cityList', data)

export const getMenuList = data => request('/erpapi/user/authority', data)

export const getQueryList = data => request('/erpapi/data/bike/queryLargeScreenCondition', data)

export const getBikeTotalData = data => request('/erpapi/screen/bike/stat', data)

export const getZoneCountLists = data => request('/erpapi/data/zone/zoneCounts', data)

export const getBikeLatLngLists = data => request('/erpapi/screen/bike/list', data)

export const signOut = data => request('/erpapi/user/logout', data, 'POST')
